import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import pagespeed from "../images/palmomedia_optimierung_gatsby_wordpress.jpg";
import entwicklung from "../images/palmomedia-entwicklung.jpg";
import { Button, Col, Form, FormText, Row } from "react-bootstrap";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  const keyPress = (e) => {
    if (e.key == "Enter") {
      const el = document.getElementById("pageurl");
      if (el.value.length > 3) checkSite();
    }
  };

  const checkSite = () => {
    if (document.getElementById("pageurl")) {
      const el = document.getElementById("pageurl");
      const url =
        "https://developers.google.com/speed/pagespeed/insights/?url=" +
        el.value +
        "&tab=mobile";
      window.open(url, "_blank");
    }
  };

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Wie wird meine Webseite schneller?" />
      <h1>Webseiten Entwicklung</h1>
      <p>
        Es ist wohl lange kein Geheimnis mehr, dass die{" "}
        <b>Zugriffsgeschwindigkeit</b> auf deine Webseite eine große Auswirkung
        auf die Sichtbarkeit deiner Seiten bei Google hat.
      </p>
      <p>
        Deswegen ist es unablässig, dass deine Webseite nicht nur <b>schnell</b>
        , sondern <b>super schnell</b> ist.
      </p>
      <p>
        Der Pagescore ist dabei entscheidend. Prüfe jetzt hier deine Webseite
        und entscheide selber wie zufrieden du mit dem Ergebnis bist. Wie es
        auch ausfällt, wir garantieren einen Mobilepagescroe von mindestens 95
        zu erreichen.
      </p>
      <Form.Group>
        <Form.Label>
          <i className="muted">
            https://developers.google.com/speed/pagespeed/insights
          </i>
        </Form.Label>
        <Row>
          <Col sm={{ span: 10, offset: 0 }}>
            <Form.Control
              id="pageurl"
              type="text"
              placeholder="https://palmomedia.de"
              onKeyPress={(e) => keyPress(e)}
            />
          </Col>
          <Col sm={{ span: 2, offset: 0 }}>
            <Button variant="primary" type="submit" onClick={() => checkSite()}>
              Prüfen
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <h3>we're building incredible fucking fast websites</h3>
      <p>
        Die nachfolgende Grafik zeigt, wie wir aus einer <b>Wordpress</b>
        -Webseite durch Umstellen auf das Framework <b>Gatsby</b> die
        Performance um{" "}
        <b>
          390<sup>%</sup>
        </b>{" "}
        gesteigert haben.
      </p>
      <img
        src={pagespeed}
        alt="entwicklung"
        className="images"
        style={{ width: "100%" }}
      />
      <h2>Unser Versprechen</h2>
      <p>
        Wir garantieren ein besseres Ranking in der Google Suche. Mit unserem
        Ansatz wird deine Webseite nicht nur schneller, sondern auch
        benutzerfreundlicher. Lass uns gemeinsam mehr aus deiner Webseite
        rausholen und deine Sichtbarkeit im Web erhöhen!
      </p>
      <img src={entwicklung} alt="entwicklung" className="images" />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
